import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import("../views/login.vue")
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/live',
    name: 'live',
    component: () => import("../views/webPushStream.vue")
  },
  {
    path:"/stuHome",
    name:"stuHome",
    component:()=>import("../views/stuHome.vue")
  }

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
