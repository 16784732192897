<template>
    <div class="live-stream">
      <div class="cover">
        <img :src="coverUrl" alt="Live Cover">
      </div>
      <div class="content">
        <div class="header">
          <h2>{{ lectureName }}</h2>
          <p>{{ title }}</p>
        </div>
        <div class="info">
          <p><span class="label">开始时间：</span>{{ startTime }}</p>
          <p><span class="label">结束时间：</span>{{ endTime }}</p>
          <p><span class="label">状态：</span>{{  getStatus(status) }}</p>
          <p>直播密码：{{ password }}</p>
          <p>用户访问密码{{ userPassword }}</p>
        </div>
        <div class="action">
            <el-button @click="handlePassword">设置密码</el-button>
            <el-button type="danger" @click="handleDelete">删除</el-button>
            <el-button type="primary" @click="editLive()">编辑直播间信息</el-button>
          <el-button type="primary" @click="startStream">开始直播</el-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {editInfo} from "@/api/api"
  import liveStatus from "@/utils/liveStatus"
  export default {
    props: {
        status:{
            type:String,
            default:''
        
        },
        password:{
            type:String,
            default:''
        },
      lectureName: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      startTime: {
        type: String,
        required: true
      },
      endTime: {
        type: String,
        required: true
      },
      coverUrl: {
        type: String,
        required: true
      },
      userPassword:{
        type:String,
        default:''
      }
    },
    methods: {
        getStatus(status){
            return  liveStatus(status)
        },
        handlePassword(){
            this.$emit("handlePassword")
        },
        handleDelete(){
            this.$emit("delete")
        },
        editLive(e){
          this.$emit("edit")
        },
      startStream() {
        // 在这里添加开始直播的逻辑
        // alert('开始直播');
       this.$emit("startStream");
      }
    }
  }
  </script>
  
  <style scoped>
  .live-stream {
    display: flex;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .cover {
    flex: 0 0 200px;
    margin-right: 20px;
  }
  
  .cover img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .content {
    flex: 1;
  }
  
  .header {
    margin-bottom: 10px;
  }
  
  .header h2 {
    font-size: 20px;
    color: #333;
    margin: 0;
  }
  
  .info {
    color: #666;
    margin-bottom: 10px;
  }
  
  .info .label {
    font-weight: bold;
  }
  
  .action {
    text-align: right;
  }
  </style>
  