export default function(status){
    switch(status){
        case 'unStart':
            return '未开始'
        case 'live':
            return '直播中'
        case 'end':
            return '已结束'
        case 'waiting':
            return '等待中'
        case 'playback':
            return '回放中'
        case 'banpush':
            return '已禁播'
    }
}