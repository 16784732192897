import request from "@/utils/request"

import { baseURL } from "@/utils/request"
export function login(data){
   return request({
        url:"/user/login",
        method:"post",
        data
    })
}

export function addLive(data){
    return request({
         url:"/live/add",
         method:"post",
         data
     })
}

export function getLive(data){
    return request({
         url:"/live/getLive",
         method:"get",
         data
     })
}
export function userInfo(data){
    return request({
         url:"/user",
         method:"get",
         data
     })
}
export function allLive(){
    return request({
         url:"/live/allLive",
         method:"get",
     })
}

export function editInfo(channelId){
    window.open(baseURL+"/live/loginblwPingdao?channelId="+channelId)
}
export function logout(data){
    return request({
         url:"/logout",
         method:"get",
         params:data
     })
}


export function deleteLive(data){
    return request({
         url:"/userLive/deleteLive",
         method:"get",
         params:data
     })
}
export function setPassword(data){
    return request({
         url:"/live/setPassword",
         method:"get",
         params:data
     })
}

export function isPassword(data) {
    return request({
         url:"/userLive/isPassword",
         method:"get",
         params:data
     })
}